


































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import EmployeeDto, {UpdateEmployeePayload} from "@/dto/payroll/EmployeeDto";
import BranchDto from "@/components/profile/branches/BranchDtos";
import EmployeeService from "@/services/EmployeeService";
import TerminationModal from "@/components/payroll/business/employments/contractors/TerminationModal.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import RouteNames from "@/router/RouteNames";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import SelectOption from "@/components/common/SelectOption";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import AddressDTO from "@/dto/AddressDTO";
import Notifications from "@/state/Notifications";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";
import Application from "@/state/Application";
import CompanyService from "@/services/CompanyService";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {ContractType, contractTypeOptions} from "@/constants/EmploymentType";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";


@Component({
  computed: {
    ContractType() {
      return ContractType
    },
    SelectOption() {
      return SelectOption
    },
    WorkspaceTypePathElement() {
      return WorkspaceTypePathElement
    }
  },
  methods: {
    contractTypeOptions: contractTypeOptions,
    RouteNames() {
      return RouteNames
    }
  },
  components: {SingleFileHolder, PortalRadio, PortalCheckbox, BTPortalCollapse, PortalInput, PortalDate, PortalSelect}
})
export default class EmployeeDetails extends EmploymentVue<EmployeeDto> {

  private detailsIsOpen = true;

  private message: string | null = null;

  private successful = false;

  private projects: ProjectDTO[] = [];

  @Prop()
  employment!: EmployeeDto

  get branches(): Array<SelectOption> {
    let branches: Array<BranchDto> = [];
    if (this.employment.employer?.headquarter) {
      branches.push(this.employment.employer.headquarter);
    }
    if (this.employment.employer?.branches) {
      branches.push(...this.employment.employer.branches)
    }
    return branches.map(branch => SelectOption.builder().title(branch.title).value(branch).build());
  }

  public created(): void {
    this.loadProjects()
  }

  private loadProjects(): void {
    Application.startLoading()
    CompanyService.getProjectsByCompanyId(this.employment.employer?.id).then(
        res => {
          this.projects = res.data
          Application.stopLoading()
        },
        err => processError(err, this)
    )
  }

  get projectOptions(): SelectOption[] {
    return this.projects.map(it => SelectOption.builder().title(it.title).value(it).build())
  }

  private applyWorkplace(): void {
    if (this.employment.workplaces.length > 0) {
      this.employment.remote = false;
    }
  }

  get workplaces(): Array<SelectOption> {
    let workplaces: Array<AddressDTO> = [];
    this.employment.branches.forEach(branch => workplaces.push(...this.assembleAddresses(branch)))
    return workplaces.map(place => SelectOption.builder().title(place.fullAddress).value(place).build());
  }

  private assembleAddresses(branch: BranchDto) {
    let res: Array<AddressDTO> = [];
    if (branch.legalAddress && branch.legalAddress.fullAddress) {
      res.push(branch.legalAddress)
    }
    if (branch.actualAddress && branch.actualAddress.fullAddress) {
      res.push(branch.actualAddress)
    }
    if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
      res.push(branch.mailingAddress)
    }
    if (branch.registrationAgentAddress && branch.registrationAgentAddress.fullAddress) {
      res.push(branch.registrationAgentAddress)
    }
    return res;
  }

  private openTerminateModal(): void {
    this.$modal.show(
        TerminationModal,
        {
          terminateId: this.employment.id,
          applyTerminate: (payload: TerminationPayloadDTO) => this.terminate(payload)
        }
    )
  }

  private terminate(payload: TerminationPayloadDTO): void {
    Application.startLoading();
    EmployeeService.terminateContractor(payload).then(
        () => {
          this.successful = true;
          this.$router.push({name: RouteNames.HR_EMPLOYEES, params: {companyId: String(this.employment.employer?.id)}})
          Application.stopLoading()
        },
        err => processError(err, this)
    )
  }

  private deleteEmployee(): void {
    Application.startLoading()
    EmployeeService.deleteContractor(this.employment.id as number).then(
        () => {
          Application.stopLoading()
          this.$router.push({name: RouteNames.HR_EMPLOYEES, params: {companyId: String(this.employment.employer?.id)}})
        },
        err => processError(err, this)
    )
  }

  private update(): void {
    ifValid(this, () => {
      Application.startLoading()
      const req = new UpdateEmployeePayload({
        id: this.employment.id,
        employmentStart: this.employment.employmentStart,
        branches: this.employment.branches,
        workplaces: this.employment.workplaces,
        remote: this.employment.remote,
        projectsId: this.employment.projects.map(it => it.id),
        officer: this.employment.officer
      })
      EmployeeService.updateContractor(req).then(
          () => {
            this.successful = true;
            Notifications.success("The employee's data has been updated")
            this.$emit("update")
            Application.stopLoading()
          },
          err => processError(err, this)
      )
    })
  }

}

